import * as Yup from 'yup';

export const UserFormValidations = Yup.object().shape({
    first_name: Yup.string().required('First name is required'),
    last_name: Yup.string().required('Last name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    photo: Yup.mixed().required('Profile photo is required'),
    country: Yup.string().required('Country is required'),
    level_of_education: Yup.string().required('Level of education is required'),
    institution: Yup.string().required('Institution is required'),
    date_of_birth: Yup.date().required('Date of birth is required'),
    gender: Yup.string().required('This field is required'),  
    password: Yup.string().min(8, 'Password must be at least 8 characters long').required('Password is required'),
    confirm_password: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Confirm password is required'),  
});