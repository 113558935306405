import Icons from "../../icons/sidebar";

export interface IMenuItem {
  title: string;
  Icon?: any;
  path: string;
  subMenu?: IMenuItem[];
  permissions?: string[];
  users?: string[];
  visible: boolean;
}

export const MenuList = (isSuperUser: boolean): IMenuItem[] => {
  return [
    {
      title: "Dashboard",
      Icon: Icons.UserGridIcon,
      path: "/dashboard",
      visible: false,
    },
    {
      title: "Tournaments",
      Icon: Icons.EmojiEventsIcon,
      path: "/dashboard/tournaments",
      visible: true,
    },
    {
      title: "Rankings",
      Icon: Icons.UserManagementIcon,
      path: "/dashboard/global-speaker-rankings",
      visible: true,
    },
    {
      title: "My Tags",
      Icon: Icons.ConfirmationNumberIcon,
      path: "/dashboard/tickets",
      visible: true,
    },
    {
      title: "Tab Master",
      Icon: Icons.AdminPanelSettingsIcon,
      path: "/admin/tournament",
      visible: true,
    },
    {
      title: "Institutions",
      Icon: Icons.Settings,
      path: "/institutions",
      visible: isSuperUser,
    },
  ];
};

export default MenuList;
