import { lazy, Suspense as ReactSuspense } from "react";
import LoadingScreen from "../components/LoadingScreen";

const Suspense: any = ReactSuspense;

const Loadable = (Component: any) => (props: any) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

const RouteLazyLoader = {
  // AUTHENTICATION ROUTES
  Login: Loadable(lazy(() => import("../pages/Authentication/Login/Login"))),
  ForgotPassword: Loadable(
    lazy(() => import("../pages/Authentication/ForgotPassword/ForgotPassword"))
  ),
  ResetPassword: Loadable(
    lazy(() => import("../pages/Authentication/ResetPassword/ResetPassword"))
  ),

  // DASHBOARD ROUTES
  DashboardSaaS: Loadable(lazy(() => import("../pages/dashboards/SaaS"))),

  // USER MANAGEMENT ROUTES
  AccountView: Loadable(lazy(() => import("../pages/Account/Common/AccountView"))),

  UserList: Loadable(
    lazy(() => import("../pages/DebaterRankings/DebatorRankings"))
  ),
  TournamentDashboard: Loadable(
    lazy(() => import("../pages/Tournaments/TournamentsList/TournamentsListDashboard"))
  ),
  // CLIENT ROUTES
  Clients: Loadable(lazy(() => import("../pages/Clients/Clients"))),
  ClientForm: Loadable(lazy(() => import("../pages/Clients/ClientsForm"))),
  ClientProfile: Loadable(lazy(() => import("../pages/Clients/ClientProfile"))),

  TicketGrid: Loadable(lazy(() => import("../pages/Tickets/TicketGrid"))),

  // TAB
  SpeakerTab: Loadable(
    lazy(() => import("../pages/DebateTab/SpeakerTab/SpeakerTab"))
  ),
  Adjudicator: Loadable(
    lazy(() => import("../pages/DebateTab/AdjudicatorTab/AdjudicatorTab"))
  ),
  TeamTab: Loadable(lazy(() => import("../pages/DebateTab/TeamTab/TeamTab"))),
  Results: Loadable(lazy(() => import("../pages/DebateTab/Results/Results"))),
  OpenBreaks: Loadable(
    lazy(() => import("../pages/DebateTab/OpenBreaks/OpenBreaks"))
  ),
  BreakingAdjudicators: Loadable(
    lazy(
      () =>
        import("../pages/DebateTab/BreakingAdjudicators/BreakingAdjudicators")
    )
  ),
  TeamProfile: Loadable(
    lazy(() => import("../pages/DebateTab/TeamTab/TeamProfile"))
  ),
  TeamRound: Loadable(
    lazy(() => import("../pages/DebateTab/Round/TeamRound/TeamRound"))
  ),
  DebateRound: Loadable(
    lazy(() => import("../pages/DebateTab/Round/DebateRound/DebateRound"))
  ),

  // INSTUTION ADMIN ROUTES
  Tournament: Loadable(
    lazy(() => import("../pages/Tournaments/TabTournaments"))
  ),
  TournamentForm: Loadable(
    lazy(() => import("../pages/Tournaments/TournamentForm/TournamentForm"))
  ),
  TournamentDetailView: Loadable(
    lazy(
      () =>
        import("../pages/Tournaments/TournamentDetails/TournamentDetailView")
    )
  ),
  RoundsDetails: Loadable(
    lazy(() => import("../pages/Tournaments/Rounds/RoundDetails/RoundDetails"))
  ),
  RoundsSettings: Loadable(
    lazy(() => import("../pages/Tournaments/Rounds/RoundsSettings"))
  ),
  RoomsSettings: Loadable(
    lazy(() => import("../pages/Admin/RoomsSettings/RoomsSettings"))
  ),

  // afritab ADMIN ROUTES
  Institution: Loadable(lazy(() => import("../pages/Institution/Institution"))),

  // Users
  Users: Loadable(lazy(() => import("../pages/Users/Users"))),

  // ERROR ROUTES
  Error404: Loadable(lazy(() => import("../pages/404"))),
  Error403: Loadable(lazy(() => import("../pages/403"))),
};

export default RouteLazyLoader;
