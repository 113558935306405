import React from "react";
import { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { Box, useTheme, useMediaQuery, Stack, Button } from "@mui/material";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { H1, Paragraph, Small } from "../../../components/Typography";
import FormFactory from "../../../components/UI/FormFactory";
import { UserFormValidations } from "../../Users/UserFormValidations";
import { UserFormFields } from "../../Users/UserFormFields";
import { primary } from "../../../theme/themeColors";
import LoadingButton from "@mui/lab/LoadingButton";
import { IInstitutionResults } from "../../Institution/Institution.interface";
import { InstitutionService } from "../../Institution/Institution.service";
import { RegisterService } from "./Register.service";
import { APP_NAME } from "../../../api/constants";
import { createFormData, prepareObjectFormdataPost } from "../../../utils/helpers";

const Register = () => {
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const formRef = useRef<HTMLFormElement | null>(null);

  const [institutions, setInsitutions] = React.useState<IInstitutionResults>({
    count: 0,
    results: [],
  });
  const [searchQuery, setSearchQuery] = React.useState<string>("");

  const navigate = useNavigate();

  const formFields = UserFormFields({
    institutions: (institutions?.results || []).map((item) => ({
      value: item.id,
      label: item.name,
    })),
    handleInstitutionSearch: (value: string) => {
      setSearchQuery(value);
    },
  });

  const submitData = async (values: any) => {
    try {
      // Construct the profile object
      const profile = {
        gender: values?.gender,
        date_of_birth: values?.date_of_birth,
        country: values?.country,
        level_of_education: values?.level_of_education,
        institution: values?.institution,
        photo: values?.photo?.[0]
      };
      values["profile"] = profile;

      const payload = createFormData({ ...values, ...prepareObjectFormdataPost(profile, 'profile')})

      const response = await RegisterService.Register(payload);
      setLoading(false);
      if (response.status === 200) {
      }
      formik.resetForm();
      toast.success("Account created successfully!");
      navigate("/login", {
        state: {
          message:
            "Your account has been successfully created. Please login to continue...",
        },
      });
    } catch (error: any) {
      console.log(error?.response?.data);
      formik.setErrors({ ...(error.response?.data || []) });
      toast.error(
        error.response?.data?.message ||
          "An error occurred while creating the account."
      );
    }
  };

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      profile: {
        date_of_birth: "",
        gender: "",
        country: "",
        state: "",
        city: "",
        current_university: "",
        student_status: "",
        alumni_university: "",
        affiliate_organization: "",
        attachment_link: "",
      },
    },
    onSubmit: submitData,
    validationSchema: UserFormValidations,
    validateOnChange: false,
    validateOnMount: false,
    validateOnBlur: false,
  });  

  useEffect(() => {
    fetchData(searchQuery);
  }, [searchQuery]);

  const fetchData = async (searchQuery?: any) => {
    try {
      const resp: IInstitutionResults =
        await InstitutionService.getInstitutions({ name: searchQuery });
      setInsitutions(resp);
    } catch (error) {
      console.error("Error fetching Teams:", error);
    }
  };

  const handleButtonClick = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      );
    }
  };

  return (
    <Box
      data-aos="fade-up"
      data-aos-duration="800"
      sx={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <Box sx={{ width: "100%", px: isMobile ? 2 : 4 }}>
        <Stack spacing={1}>
          <H1 fontSize={28} fontWeight={800} mb={3}>
            Sign <span style={{ color: primary.main }}>Up</span>
          </H1>
          <H1 fontSize={16} fontWeight={700}>
            Create an account on <span className="mainColor">{APP_NAME}</span>!
          </H1>
          <Paragraph pb={3} fontSize={12}>
            Revolutionizing Debate
          </Paragraph>
        </Stack>

        <form
          ref={formRef}
          onSubmit={formik.handleSubmit}
          encType="multipart/form-data"
        >
          <Box sx={{ width: "100%" }}>
            <FormFactory
              others={{ sx: { marginBottom: "0rem" } }}
              formikInstance={formik}
              formFields={formFields}
              validationSchema={UserFormValidations}
            />
          </Box>

          <Box sx={{ mt: 4 }}>
            {loading ? (
              <LoadingButton
                loading
                fullWidth
                variant="contained"
                sx={{ bgcolor: "#242649" }}
              >
                Submitting...
              </LoadingButton>
            ) : (
              <Button
                fullWidth
                type="button"
                variant="contained"
                // sx={{ bgcolor: "#242649" }}
                onClick={handleButtonClick}
              >
                Register
              </Button>
            )}
          </Box>
        </form>
        <Small
          margin="auto"
          mt={3}
          textAlign={"center"}
          style={{ display: "block" }}
          color="text.disabled"
        >
          Already have an account?
          <Link to="/login">
            <Small color="primary.main" sx={{ ml: 1 }} className="semiBold">
              Login Now
            </Small>
          </Link>
        </Small>
      </Box>
    </Box>
  );
};

export default Register;
