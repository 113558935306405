import { FC } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import InfoSlider from "../../components/sliders/InfoSlider";
import { primary } from "../../theme/themeColors";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import Login from "./Login/Login";
import Register from "./Register/Register";

export const LoginPortal: FC = () => {
  const location = useLocation().pathname;

  function getComponent() {
    switch (location) {
      case "/login":
        return <Login />;
      case "/forgot-password":
        return <ForgotPassword />;
      case "/register":
        return <Register />;
      default:
        break;
    }
  }

  return (
    <WrapperMain id="login" className="flexSpaceCenter">
      <LeftSide className="redBg flexCenter flexColumn animate poka_pattern_dark">
        <div className="x100">
          <InfoSlider />
        </div>
      </LeftSide>
      <RightSide className="whiteBg">{getComponent()}</RightSide>
    </WrapperMain>
  );
};

const WrapperMain = styled.section`
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 55%;
  background-color: ${primary.main};
  gap: 20px;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
  }
`;

const RightSide = styled.div`
  width: 45%;
  height: 100%;
  overflow: auto;
  display: inline-grid;
  @media (max-width: 960px) {
    width: 100%;
    height: auto;
    order: 1;
    text-align: center;
    padding: 20px;
    overflow: inherit;
  }
`;
