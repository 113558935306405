import { countries, genderFields, levelsOfStudy } from "../../api/constants";
import { IFormField } from "../../utils/form_factory";
import { TUserFormProps } from "./Users.interface";


export const UserFormFields = (props: TUserFormProps): IFormField[] => {
    const { institutions, handleInstitutionSearch } = props;

    return [
        {
            name: 'first_name',
            initailValue: '',
            label: 'First Name',
            type: 'text',
            uiType: 'text',
            uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            required: true,
        },
        {
            name: 'last_name',
            initailValue: '',
            label: 'Last Name',
            type: 'text',
            uiType: 'text',
            uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            required: true,
        },
        {
            name: 'other_name',
            initailValue: '',
            label: 'Other Name',
            type: 'text',
            uiType: 'text',
            uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
        },
        {
            name: 'email',
            initailValue: '',
            label: 'Email',
            type: 'email',
            uiType: 'email',
            uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            required: true,
        },
        
        {
            name: 'country',
            initailValue: '',
            label: 'Nationality',
            type: 'select',
            uiType: 'select',
            uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            options: countries,
            required: true,
        },
        {
            name: 'date_of_birth',
            initailValue: '',
            label: 'Date of Birth',
            uiType: 'date',
            uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            required: true,
        },
        {
            name: 'gender',
            initailValue: '',
            label: 'Gender',
            type: 'select',
            uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            uiType: 'select',
            options: genderFields,
            required: true,
        },

        {
            name: 'photo',
            initailValue: '',
            label: 'Profile photo',
            type: 'file',
            uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            uiType: 'file',
            accepts: 'image/*',
            required: true,
        },

        {
            name: 'level_of_education',
            initailValue: '',
            label: 'Level of Education',
            type: 'select',
            uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            uiType: 'select',
            options: levelsOfStudy,
            required: true,
        },
        {
            name: 'institution',
            initailValue: '',
            label: 'Current/Former Institution',
            type: 'select',
            uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            uiType: 'select',
            options: institutions,
            required: true,
            handleSearch: handleInstitutionSearch
        },
        {
            name: 'password',
            initailValue: '',
            label: 'Password',
            type: 'password',
            uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            uiType: 'password',
            required: true,
        },
        {
            name: 'confirm_password',
            initailValue: '',
            label: 'Confirm Password',
            type: 'password',
            uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            uiType: 'password',
            required: true,
        },
    ]
}