import { Navigate } from "react-router-dom";
import RouteGuard from "../guards/RouteGuard";
import AuthGuard from "../components/authentication/AuthGuard";
import GuestGuard from "../components/authentication/GuestGuard";
import DashboardLayout from "../components/Layouts/DashboardLayout";
import { LoginPortal } from "../pages/Authentication/LoginPortal";
import {
  CLIENT_TYPE_ADJUDICATOR,
  CLIENT_TYPE_DEBATER,
  CLIENT_TYPE_INSTITUTION,
} from "../constants/client";
import LazyLoader from "./routes-loader";
import ResetPassword from "../pages/Authentication/ResetPassword/ResetPassword";
import { ACCOUNT_PAST_TOURNAMENTS, ACCOUNT_PROFILE } from "../api/constants";

const routes = [
  {
    path: "/",
    element: <Navigate to="dashboard" />,
  },
  {
    path: "login",
    element: (
      <GuestGuard>
        <LoginPortal />
      </GuestGuard>
    ),
  },
  {
    path: "register",
    element: (
      <GuestGuard>
        <LoginPortal />
      </GuestGuard>
    ),
  },
  {
    path: "forgot-password",
    element: (
      <GuestGuard>
        <LoginPortal />
      </GuestGuard>
    ),
  },
  {
    path: "reset-password",
    element: (
      <GuestGuard>
        <ResetPassword />
      </GuestGuard>
    ),
  },

  {
    path: "",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "tournaments/details/:tournamentId",
        element: <LazyLoader.TournamentDetailView />,
        requiresAuth: true,
      },
      {
        path: "tournaments/details/:tournamentId/:tabId",
        element: <LazyLoader.TournamentDetailView />,
        requiresAuth: true,
      },
    ],
  },

  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <LazyLoader.TournamentDashboard />,
      },
      {
        path: "account/:tabId",
        element: <LazyLoader.AccountView />,
      },
      {
        path: "global-speaker-rankings",
        element: <LazyLoader.UserList />,
      },
      {
        path: "tickets",
        element: <LazyLoader.TicketGrid />,
      },
      {
        path: "tournaments",
        element: <LazyLoader.TournamentDashboard />,
      },
    ],
  },

  {
    path: "forbidden",
    element: <LazyLoader.Error403 />,
  },
  {
    path: "*",
    element: <LazyLoader.Error404 />,
  },

  {
    path: "tab",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "team",
        element: <LazyLoader.TeamTab />,
        requiresAuth: true,
      },
      {
        path: "speaker",
        element: <LazyLoader.SpeakerTab />,
        requiresAuth: true,
      },
      {
        path: "adjudicator",
        element: <LazyLoader.Adjudicator />,
        requiresAuth: true,
      },
      {
        path: "results",
        element: <LazyLoader.Results />,
        requiresAuth: true,
      },
      {
        path: "open-breaks",
        element: <LazyLoader.OpenBreaks />,
        requiresAuth: true,
      },
      {
        path: "breaking-adjudicators",
        element: <LazyLoader.BreakingAdjudicators />,
        requiresAuth: true,
      },
      {
        path: "team-profile/:teamId",
        element: <LazyLoader.TeamProfile />,
      },
      {
        path: "team-round/:roundId",
        element: <LazyLoader.TeamRound />,
      },
      {
        path: "debate-round/:roundId",
        element: <LazyLoader.DebateRound />,
      },
    ],
  },

  {
    path: "clients",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "debater",
        element: (
          <LazyLoader.Clients
            client_type={CLIENT_TYPE_DEBATER}
            title={"Debaters"}
          />
        ),
      },
      {
        path: "institution",
        element: (
          <LazyLoader.Clients
            client_type={CLIENT_TYPE_INSTITUTION}
            title={"Institutions"}
          />
        ),
      },

      {
        path: "adjudicator",
        element: (
          <LazyLoader.Clients
            client_type={CLIENT_TYPE_ADJUDICATOR}
            title={"Adjudicators"}
          />
        ),
      },
      {
        path: "create-client",
        element: <LazyLoader.ClientForm />,
      },
      {
        path: "client-profile/:clientId",
        element: <LazyLoader.ClientProfile />,
      },
    ],
  },
  {
    path: "admin",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "tournament",
        element: <LazyLoader.Tournament />,
        requiresAuth: true,
      },
      {
        path: "tournament/create",
        element: <LazyLoader.TournamentForm />,
        requiresAuth: true,
      },
      {
        path: "tournament/details/:tournamentId",
        element: <LazyLoader.TournamentDetailView />,
        requiresAuth: true,
      },
      {
        path: "rounds/:round_id/details",
        element: <LazyLoader.RoundsDetails />,
        requiresAuth: true,
      },
      {
        path: "rounds-settings",
        element: <LazyLoader.RoundsSettings />,
        requiresAuth: true,
      },
      {
        path: "rooms-settings",
        element: <LazyLoader.RoomsSettings />,
        requiresAuth: true,
      },
    ],
  },
  {
    path: "institutions",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/institutions",
        element: (
          <RouteGuard requiredPermissions={[]}>
            <LazyLoader.Institution />
          </RouteGuard>
        ),
        requiresAuth: true,
      },
    ],
  },
  {
    path: "account",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "profile",
        element: <LazyLoader.AccountView />,
        requiresAuth: true,
      },
    ],
  },
  {
    path: "users",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/users",
        element: (
          <RouteGuard requiredPermissions={[]}>
            <LazyLoader.Users />
          </RouteGuard>
        ),
        requiresAuth: true,
      },
    ],
  },
];

export default routes;
